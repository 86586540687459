import { groupBy, sortWith, ascend, prop, propOr, uniqWith, eqProps, and } from "ramda";
import { onMounted, watch, computed, ref, unref, nextTick } from "vue";

import useLogger from "@/composables/useLogger";
import { useVehicleDataApi } from "@/composables/useVehicleDataApi";
import { useFinanceDataApi } from "@/composables/useFinanceDataApi";
import { useVehicleModelStore } from "@/stores/useVehicleModelStore";
import { useAppIntegration } from "@/stores/useAppIntegration";
import { storeToRefs } from "pinia";
import { MARKET_VALUATOR_LUTHERBUYSCARS } from "~/lib/constants";

import { FINANCE_TYPE_LOAN, FINANCE_TYPE_LEASE, FINANCE_TYPE_NO_LOAN, FINANCE_TYPE_MANUAL } from "@/stores/useVehicleModelStore";

import type { VehicleOptionData, VehicleDetailByVinData } from "@/types/vehicle";
import { MarketValuation } from "./useMarketValuations/types";
import useMarketValuationConfig from "./useMarketValuationConfig";

const {
  onLoadVehicleYears,
  onLoadVehicleMakes,
  onLoadVehicleModels,
  onLoadVehicleTrims,
  onLoadVehiclesByYMMT,
  onLoadVehicleOptions,
  onApplyVehicleConfiguration,
  onValidateVehicleConfiguration,
  onLookupVehicleDetailByPlate,
  onLookupVehicleByVin,
  onLoadVehicleValues,
} = useVehicleDataApi();

const { onGetLenders, onGetPayoffQuote } = useFinanceDataApi();

interface VehicleOption {
  categoryName?: string;
  categoryGroup: string;
  sortOrder: number;
}

export interface R4SearchParams {
  email: string;
  // phone: string; // will support phone later
}

export interface R4SearchVehicle {
  email: string;
  vin: string;
  vehicle_year: number | string;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_series: string | null;
  vehicle_trim: string | null;
  last_visit: string | null;
  had_sale: boolean;
  had_service: boolean;
}

export const useVehicleModel = () => {
  const logger = useLogger("useVehicleModel");
  const store = useVehicleModelStore();

  /**
   * Storage for initialializing outside of the composable
   */
  type StartQuoteDetail = {
    workflowName: string;
    yearId: number;
    makeId: number;
    makeName: string;
    modelId: number;
    modelName: string;
    vin: string;
  };

  /**
   * Lists of available vehicle options
   */
  const {
    vehicleYears,
    vehicleMakes,
    vehicleModels,
    allVehicleTrims,
    vehicleDetails,
    vinDecodedVehicleDetails,
    vehicleAllAvailableOptions,
  } = storeToRefs(store);

  const vehicleTrims = computed({
    get() {
      if (vinDecodedVehicleDetails.value?.length > 0) {
        return vinDecodedVehicleDetails.value;
      } else {
        return allVehicleTrims.value;
      }
    },
    set(value) {
      allVehicleTrims.value = value;
    },
  });

  // grouped options and specific helpers
  const _sortOptBySortOrder = sortWith([ascend(prop("sortOrder"))]);
  const _generalOptions = computed(() => {
    return vehicleAllAvailableOptions.value.filter((opt) => !isSingleSelectOption(opt));
  });
  const vehicleAllAvailableGroupedOptions = computed(() => {
    if (_generalOptions.value.length === 0) {
      return {};
    } else {
      return groupBy((option: VehicleOptionData) => {
        return option.categoryGroup;
      }, _sortOptBySortOrder(_generalOptions.value));
    }
  });

  const availableColors = computed(() => {
    if (vinDecodedVehicleDetails.value?.length > 0) {
      const selectedVehicleWithTrim = vinDecodedVehicleDetails.value.find((v) => v.trimName === vehicleTrim.value);
      const vehicleColors = (selectedVehicleWithTrim?.vehicleOptions || []).filter((opt) => {
        // note: isVinDecoded == decoded specific to the vin
        return opt.isVinDecoded && opt.categoryName === SingleCategoryMapping.MainBodyColor;
      });
      if (vehicleColors?.length > 0) {
        return vehicleColors;
      }
    }
    return vehicleAllAvailableOptions.value.filter((option: VehicleOption) => option?.categoryName === SingleCategoryMapping.MainBodyColor);
  });

  const availableTransmissions = computed(() => {
    if (vinDecodedVehicleDetails.value?.length > 0) {
      const selectedVehicleWithTrim = vinDecodedVehicleDetails.value.find((v) => v.trimName === vehicleTrim.value);
      const vehicleTransmissions = (selectedVehicleWithTrim?.vehicleOptions || []).filter((opt) => {
        // note: isVinDecoded == decoded specific to the vin
        return opt.isVinDecoded && opt.categoryName === SingleCategoryMapping.Transmission;
      });
      if (vehicleTransmissions?.length > 0) {
        return vehicleTransmissions;
      }
    }
    return vehicleAllAvailableOptions.value.filter((option: VehicleOption) => option?.categoryName === SingleCategoryMapping.Transmission);
  });

  const availableDrivetrains = computed(() => {
    if (vinDecodedVehicleDetails.value?.length > 0) {
      const selectedVehicleWithTrim = vinDecodedVehicleDetails.value.find((v) => v.trimName === vehicleTrim.value);
      const vehicleDrivetrain = (selectedVehicleWithTrim?.vehicleOptions || []).filter((opt) => {
        // note: isVinDecoded == decoded specific to the vin
        return opt.isVinDecoded && opt.categoryName === SingleCategoryMapping.Drivetrain;
      });
      if (vehicleDrivetrain?.length > 0) {
        return vehicleDrivetrain;
      }
    }
    return vehicleAllAvailableOptions.value.filter((option: VehicleOption) => option?.categoryName === SingleCategoryMapping.Drivetrain);
  });

  const availableEngines = computed(() => {
    if (vinDecodedVehicleDetails.value?.length > 0) {
      const selectedVehicleWithTrim = vinDecodedVehicleDetails.value.find((v) => v.trimName === vehicleTrim.value);
      const vehicleEngines = (selectedVehicleWithTrim?.vehicleOptions || []).filter((opt) => {
        // note: isVinDecoded == decoded specific to the vin
        return opt.isVinDecoded && opt.categoryName === SingleCategoryMapping.Engine;
      });
      if (vehicleEngines?.length > 0) {
        logger.debug(">> Returning  Vin decoded vehicle engine");
        return vehicleEngines;
      }
    }
    logger.debug(">> Returning ALL vehicle engine");
    return vehicleAllAvailableOptions.value.filter((option: VehicleOption) => option?.categoryName === SingleCategoryMapping.Engine);
  });

  const appIntegration = useAppIntegration();
  const { dealerKbbConditionOptions } = appIntegration;
  const vehicleConditions = dealerKbbConditionOptions;
  // const vehicleConditions = ref(["Very Good", "Good", "Fair"]);
  const yesNoAnswers = ref(["Yes", "No"]);

  /**
   * Market Valuations
   */
  const { marketValuationId, selectedMarketValuation } = storeToRefs(store);

  /**
   * Vehicle Instance & Accessors
   */

  const { workflowName, customerName, customerEmail, customerPhone, vehicleInstance } = storeToRefs(store);

  const vehicleVin = computed(buildComputedVehicleGetterSetter("vin"));
  const vehicleYear = computed(buildComputedVehicleGetterSetter("vehicle_year"));
  const vehicleMake = computed(buildComputedVehicleGetterSetter("vehicle_make"));
  const vehicleModel = computed(buildComputedVehicleGetterSetter("vehicle_model"));
  const vehicleTrim = computed(buildComputedVehicleGetterSetter("vehicle_trim"));

  const mileage = computed(buildComputedVehicleGetterSetter("mileage"));
  const postalCode = computed(buildComputedVehicleGetterSetter("postal_code"));
  const condition = computed(buildComputedVehicleGetterSetter("condition"));
  const accidentHistory = computed(buildComputedVehicleGetterSetter("accident_history"));
  const isUndrivable = computed(buildComputedVehicleGetterSetter("is_undrivable"));
  const defectsWarnings = computed(buildComputedVehicleGetterSetter("defects_warnings"));
  const exteriorDamage = computed(buildComputedVehicleGetterSetter("exterior_damage"));
  const salvageTitle = computed(buildComputedVehicleGetterSetter("salvage_title"));

  const vehiclePlateNumber = computed(buildComputedVehicleGetterSetter("vehicle_plate_number"));
  const vehiclePlateState = computed(buildComputedVehicleGetterSetter("vehicle_plate_state"));
  const { vehiclePlateDetails, vinSearchResults } = storeToRefs(store);

  // const r4VehicleSearchResults = ref<R4SearchVehicle[]>([]);

  const optionEngine = computed(buildComputedVehicleGetterSetter("option_engine"));
  const optionTransmission = computed(buildComputedVehicleGetterSetter("option_transmission"));
  const optionDrivetrain = computed(buildComputedVehicleGetterSetter("option_drive_train"));
  const optionColor = computed(buildComputedVehicleGetterSetter("option_color"));

  // Vehicle Options....
  const {
    _vehicleFullConfiguredOptions, // reference to the kbb vehicle options that have been selected
    vehicleOptionsValidationResults, // holds the validation results...
  } = storeToRefs(store);

  // all vehicle options to be used as the v-model for all vehicle options
  const vehicleFullConfiguredOptions = computed({
    get() {
      return _vehicleFullConfiguredOptions.value;
    },
    set(optionItem: VehicleOptionData | VehicleOptionData[]) {
      // if you give a list, it overwrites the whole list (allows for initial assignment)
      if (Array.isArray(optionItem)) {
        logger.debug("setVehicleOptionList - overwrite with new", optionItem);
        _vehicleFullConfiguredOptions.value = optionItem;
      } else {
        logger.debug("setVehicleOption", optionItem);
        if (optionItem.categoryName) {
          _updateCategoryOptions(optionItem);
        } else {
          _updateVehicleOptions(optionItem);
        }
      }
    },
  });

  // update vehicle options without a category name
  async function _updateVehicleOptions(optionItem: VehicleOptionData) {
    if (optionItem.categoryName) {
      throw new Error(`updateVehicleOptions is only for options without a category. Passed Option: ${optionItem}`);
    }

    // is this an addition or removal from list? - if in list, it's a remove
    const addOrRemove = _vehicleFullConfiguredOptions.value.find((opt) => opt.vehicleOptionId === optionItem.vehicleOptionId)
      ? "removeOpt"
      : "addOpt";

    // check to see if hasRelationship - if yes, need to make api call - otherwise can skip
    if (!optionItem.hasRelationships) {
      // *does not* hasRelationship -  we can just add it to the list directly
      let targetOpts = [...unref(_vehicleFullConfiguredOptions)]; // make a copy!
      if (addOrRemove === "addOpt") {
        targetOpts.push(optionItem);
      } else {
        // we are removing... get all except the optionItem
        targetOpts = targetOpts.filter((opt) => opt.vehicleOptionId !== optionItem.vehicleOptionId);
      }
      _vehicleFullConfiguredOptions.value = targetOpts;
      return;
    }

    // we have to make an api call from here... b/c item.hasRelationships
    const newOptionIds = <number[]>[];
    const removeOptionIds = <number[]>[];

    if (addOrRemove === "removeOpt") {
      removeOptionIds.push(optionItem.vehicleOptionId);
    } else {
      newOptionIds.push(optionItem.vehicleOptionId);
    }

    // applyVehicleConfig saves the errors and commits the new configureOptions from response
    return await _applyVehicleConfiguration({ newOptionIds, removeOptionIds });
  }

  async function _updateCategoryOptions(optionItem: VehicleOptionData) {
    if (!optionItem.categoryName) {
      throw new Error(`updateCategoryOptions is only for options with a category. Passed Option: ${optionItem}`);
    }
    // SETUP - find the option and it's index
    let targetOpts = [...unref(_vehicleFullConfiguredOptions)]; // make a copy!
    // can't have two options with the same category name
    const existingOptIdx = targetOpts.findIndex((opt) => opt.categoryName === optionItem.categoryName);
    let existingOpt = null;
    if (existingOptIdx > -1) {
      existingOpt = targetOpts[existingOptIdx];
    }

    // check to see if hasRelationship - if yes, need to make api call - otherwise can skip
    if (!optionItem.hasRelationships) {
      // *does not* hasRelationship -  we can just add it to the list directly
      if (existingOptIdx > -1) {
        // found a match - replace existing...
        targetOpts.splice(existingOptIdx, 1, optionItem);
      } else {
        targetOpts.push(optionItem);
      }
      _vehicleFullConfiguredOptions.value = targetOpts;
      return;
    }

    // we have to make an api call from here...
    // we know item.hasRelationships
    const newOptionIds = <number[]>[];
    const removeOptionIds = <number[]>[];

    if (existingOpt) {
      // found a match - add it to the removal list
      removeOptionIds.push(existingOpt.vehicleOptionId);
    }
    // check to see if the optionItem is already in the list - if yes, then user is attempting to de-select it
    if (existingOpt?.vehicleOptionId !== optionItem.vehicleOptionId) {
      newOptionIds.push(optionItem.vehicleOptionId);
    }

    // applyVehicleConfig saves the errors and commits the new configureOptions from response
    return await _applyVehicleConfiguration({ newOptionIds, removeOptionIds });
  }

  async function _applyVehicleConfiguration({ newOptionIds, removeOptionIds }) {
    const vehicleOptionIds = _vehicleFullConfiguredOptions.value.map((opt: VehicleOptionData) => opt.vehicleOptionId);
    const vehicleId = vehicleDetails.value.vehicleId;
    const configResp = await onApplyVehicleConfiguration(vehicleId, vehicleOptionIds, newOptionIds, removeOptionIds);
    // based on the returned vehicle config, reset all options to the response
    // get the optionIds
    const finalConfigOptIds = configResp.finalConfiguration.vehicleOptionIds;

    /**
     * With the resulting data, kbb returns a set of vehicleOptionIds in finalConfiguration
     * we need to take that list and re-apply it to our selected options because this list
     * is valid (probably).  For example, if alloy wheels was initially selected and you choose premium wheels,
     * in the resulting data, the original alloy wheels will be unselected
     */
    const configuredOptions = vehicleAllAvailableOptions.value.filter((opt: VehicleOptionData) =>
      finalConfigOptIds.includes(opt.vehicleOptionId)
    );
    _vehicleFullConfiguredOptions.value = configuredOptions;

    // add the result back to the validation results and any warnings will be blended in
    logger.debug("ApplyVehicleConfig", JSON.stringify(configResp, null, 2));
    vehicleOptionsValidationResults.value = configResp;
  }

  async function validateVehicleConfiguration() {
    const vehicleOptionIds = vehicleFullConfiguredOptions.value.map((opt: VehicleOptionData) => opt.vehicleOptionId);
    const vehicleId = vehicleDetails.value.vehicleId;
    const resp = await onValidateVehicleConfiguration(vehicleId, vehicleOptionIds);
    vehicleOptionsValidationResults.value = resp;
  }

  const isOptionConfigurationValid = computed(() => {
    return propOr(true, "isValid", vehicleOptionsValidationResults.value);
  });

  function _lookupOptionById(id: number) {
    return vehicleAllAvailableOptions.value.find((opt) => opt.vehicleOptionId === id);
  }

  const invalidOptions = computed(() => {
    const warnings = propOr([], "warnings", vehicleOptionsValidationResults.value);

    if (warnings.length === 0) {
      return [];
    }

    return warnings.map((warning) => {
      // the warning *might* have a parentId
      const parentLookup = [];
      const parentId = prop("parentId", warning);
      if (parentId) {
        parentLookup.push(_lookupOptionById(parentId));
      }
      const childLookup = warning.childIds.map((childId) => {
        return _lookupOptionById(childId);
      });

      const messageKeyLookup = [...parentLookup, ...childLookup].reduce((accu, opt) => {
        accu[opt.vehicleOptionId] = opt.optionName;
        return accu;
      }, {});

      let betterMessage = warning.message;
      // lookup both parent and child for messages, otherwise might end up with message like 123 is dependent on "Leather"
      for (const [key, val] of Object.entries(messageKeyLookup)) {
        betterMessage = betterMessage.replace(key, `**${val}**`);
      }
      return {
        options: Object.values([...parentLookup, ...childLookup]),
        message: betterMessage,
      };
    });
  });

  enum SingleCategoryOptionKey {
    Engine = "option_engine",
    Transmission = "option_transmission",
    Drivetrain = "option_drive_train",
    "Main Body Color" = "option_color",
  }
  enum SingleCategoryMapping {
    Engine = "Engine",
    Transmission = "Transmission",
    Drivetrain = "Drivetrain",
    MainBodyColor = "Main Body Color",
  }

  const optionColorDetail = computed({
    get() {
      return vehicleFullConfiguredOptions.value.filter(
        (option: VehicleOption) => option?.categoryName === SingleCategoryMapping.MainBodyColor
      );
    },
    set(value) {
      _updateCategoryOptions(value);
    },
  });

  const optionTransmissionDetail = computed({
    get() {
      return vehicleFullConfiguredOptions.value.filter(
        (option: VehicleOption) => option?.categoryName === SingleCategoryMapping.Transmission
      );
    },
    set(value) {
      _updateCategoryOptions(value);
    },
  });

  const optionDrivetrainDetail = computed({
    get() {
      return vehicleFullConfiguredOptions.value.filter(
        (option: VehicleOption) => option?.categoryName === SingleCategoryMapping.Drivetrain
      );
    },
    set(value) {
      _updateCategoryOptions(value);
    },
  });

  const optionEngineDetail = computed({
    get() {
      return vehicleFullConfiguredOptions.value.filter((option: VehicleOption) => option?.categoryName === SingleCategoryMapping.Engine);
    },
    set(value) {
      _updateCategoryOptions(value);
    },
  });

  /**
   * Vehicle Finance Configuration
   */

  const {
    financeType,
    financeTypes,

    financeTerm,
    financeMontlyPayment,
    financeLenderId,

    financeInquiryType,
    financeInquiryValue,
    financePayoffQuoteCustomerConsent,
  } = storeToRefs(store);

  const valuationPrice = computed(() => {
    if (condition.value) {
      logger.debug("HAS CONDITION - Calculating");
      return valuationPriceByCondition(condition.value)?.configuredValue || 0;
    } else {
      logger.debug("NO CONDITION - Skipping");
      return 0;
    }
  });

  const calculatedValuationPrice = computed(() => {
    return valuationPrice.value - (financePayoffNetAmount.value || 0);
  });

  /**
   * Returns the available inquiry types for the selected financeSourceId
   * This value changes based on the availableInquiryTypes for the selected financeSourceId
   * This will be some combination of VIN, ACC & SSN
   * See Also: API Docs for /api/v1/poqLenders in README.md
   */
  const financeInquiryTypes = computed(() => {
    const lender = financeLenders.value.find((lender) => lender.fsId === financeLenderId.value);
    if (lender) {
      return lender.availableInquiryTypes;
    } else {
      return [];
    }
  });

  const { financeLenders, financePayoffQuoteDetail } = storeToRefs(store);

  const loadFinanceLenders = async () => {
    const resp = await onGetLenders();
    financeLenders.value = resp.sort((a, b) => a.fsName.localeCompare(b.fsName, "en"));
  };

  // {
  //      "NetPayoffAmount": 23111.8,
  //      "GoodThroughDate": "2023-12-30 00:00:00",
  //      "PerDiem": 1.956,
  //      "GrossPayoffAmount": 23111.8,
  //      "SecurityDepositAmount": null
  // }
  const getPayoffQuote = async () => {
    const resp = await onGetPayoffQuote(
      financeLenderId.value,
      financeType.value === "Lease" ? "Lease" : "Retail",
      financeInquiryType.value,
      financeInquiryValue.value,
      financePayoffQuoteCustomerConsent.value
    );
    if (vehicleVin.value && resp[vehicleVin.value]) {
      financePayoffQuoteDetail.value = resp[vehicleVin.value];
    } else {
      financePayoffQuoteDetail.value = resp;
    }
  };

  /**
   * For payoff amount, we could use one or the other - currently using Net
   * if you develop this in a different app, pick one or the other consistently
   */
  const financePayoffGrossAmount = computed({
    get() {
      return parseFloat(propOr(null, "GrossPayoffAmount", propOr({}, "PayoffQuote", financePayoffQuoteDetail.value))) || null;
    },
    set(value) {
      financePayoffQuoteDetail.value = {
        ...(financePayoffQuoteDetail.value || {}),
        ...{ PayoffQuote: { GrossPayoffAmount: value } },
      };
    },
  });
  const financePayoffNetAmount = computed({
    get() {
      return parseFloat(propOr(null, "NetPayoffAmount", propOr({}, "PayoffQuote", financePayoffQuoteDetail.value))) || null;
    },
    set(value) {
      financePayoffQuoteDetail.value = {
        ...(financePayoffQuoteDetail.value || {}),
        ...{ PayoffQuote: { NetPayoffAmount: value } },
      };
    },
  });
  const financePayoffGoodThroughDate = computed(() => {
    return propOr(null, "GoodThroughDate", propOr({}, "PayoffQuote", financePayoffQuoteDetail.value));
  });

  /**
   * Utility functions
   */

  /**
   * given a vehicle plate which returns a vin, lookup the vehicle by vin
   * accounting for any differences between spelling of the year make model
   * also include kbb year|make|model ID's so it can be direcly looked up
   * when handed off to lutherbuyscars
   */
  const lookupVehicleByPlate = async (plateNumber: string | undefined, plateState: string | undefined) => {
    const targetPlate = plateNumber || vehiclePlateNumber.value;
    const targetPlateState = plateState || vehiclePlateState.value;
    if (!targetPlate || !targetPlateState) {
      throw new Error("Plate Number and State are required");
    }
    try {
      const plateResp = await onLookupVehicleDetailByPlate(targetPlateState, targetPlate);
      logger.info("plateLookup", JSON.stringify(plateResp, null, 2));
      if (plateResp && plateResp.vehicles && plateResp.vehicles.length > 0) {
        // save the plate lookup response from experian
        vehiclePlateDetails.value = plateResp;
        // save the vin from the resp
        vehicleVin.value = plateResp.vehicles[0].vin;
        return await findVehicleByVin(plateResp.vehicles[0].vin);
      } else {
        throw new Error("Plate not found");
      }
    } catch (error) {
      console.log(error);
      throw new Error("The plate you entered is not valid or was not found. Please try again or choose to look up by VIN.");
    }
  };

  async function findVehicleByVin(vin: string, refreshUi: boolean = true) {
    try {
      const result = await onLookupVehicleByVin(vin);
      logger.debug("findVehicleByVin", JSON.stringify(result, null, 2));
      if (result.length === 0) {
        vehicleVin.value = null;
        vinDecodedVehicleDetails.value = [];
        return null;
      } else if (result.length === 1) {
        vehicleVin.value = vin;
        // set this for filtering
        vinDecodedVehicleDetails.value = result;

        if (result[0].vehicleId) {
          // full vin match with vehicleId
          const foundVehicle = result[0];
          if (refreshUi) {
            await _uiFriendlySetVehicle(
              foundVehicle.yearId,
              foundVehicle.makeId,
              foundVehicle.makeName,
              foundVehicle.modelId,
              foundVehicle.modelName,
              foundVehicle.trimId,
              foundVehicle.trimName
            );
          }
          return { ...foundVehicle, vin: vin };
        } else {
          // no result[0].vehicleId
          //1D4HD57M59U125977 - invalid - partial match
          // { "yearId": 2009, "makeId": 13, "makeName": "Dodge" }
          let errorMsg = `The VIN you entered is not valid or was not found. Please try again or choose to look up by license plate.`;
          throw new Error(errorMsg);
        }
      } else {
        // Multiple Matches - this could be either
        //   1) KBB sends us same YMM with different trims
        //   2) Different Vehicle YMM, e.g. GMC Jimmy & Chevy Blazer (not exact but we found an example like this)
        // if there more than 1 after the YMM filter, we can return those back to the user to select say Jimmy or Blazer
        const predicate = and(and(eqProps("yearId"), eqProps("makeId")), eqProps("modelId"));
        const ymmUnique = uniqWith(predicate, result);

        if (ymmUnique.length === 1) {
          vehicleVin.value = vin;
          vinDecodedVehicleDetails.value = result;
          if (refreshUi) {
            await _uiFriendlySetVehicle(
              ymmUnique[0].yearId,
              ymmUnique[0].makeId,
              ymmUnique[0].makeName,
              ymmUnique[0].modelId,
              ymmUnique[0].modelName
            );
          }
          return { ...ymmUnique[0], vin: vin };
        } else {
          // add the vin to the result so we can use it later w/o having to re-lookup
          vinSearchResults.value = ymmUnique.map((v) => ({ ...v, vin: vin }));
          return vinSearchResults.value;
        }
      }
    } catch (error) {
      logger.error("Error finding vehicle by vin.", error);
      throw error;
      // return null;
    }
  }

  async function setVehicle(kbbVehicle: VehicleDetailByVinData) {
    const vinLookup = await onLookupVehicleByVin(kbbVehicle.vin);
    // handle the case (GMC Sierra & possibly others) where the vin lookup returns multiple models where make might be 2500 HD Extended Cab or 2500 Extended Cab
    // when we encounter this scenario, we need to filter by all YMM that match because there could be more than 1
    // if we do not do this, we could force selecting the incorrect trim
    const vinLookupFiltered = vinLookup.filter((v) => {
      return v.yearId === kbbVehicle.yearId && v.makeId === kbbVehicle.makeId && v.modelId === kbbVehicle.modelId;
    });
    // this needs to be set to properly filter actually possible values
    vinDecodedVehicleDetails.value = vinLookupFiltered;

    let trimId, trimName;
    // set the trim if only one, if we end up with more than one, we will have to let the user select
    if (vinLookupFiltered.length === 1) {
      trimId = vinLookupFiltered[0].trimId;
      trimName = vinLookupFiltered[0].trimName;
    }

    vehicleVin.value = kbbVehicle.vin;
    return _uiFriendlySetVehicle(
      kbbVehicle.yearId,
      kbbVehicle.makeId,
      kbbVehicle.makeName,
      kbbVehicle.modelId,
      kbbVehicle.modelName,
      trimId,
      trimName
    );
  }

  async function _uiFriendlySetVehicle(
    yearId: number,
    makeId: number,
    makeName: string,
    modelId: number,
    modelName: string,
    trimId?: number,
    trimName?: string
  ) {
    // NOTE/IMPORTANT
    // Walk through updating this like you would in the UI
    // since we are setting variables in bulk, we have to pause reactivity so things like setting vehicle model do not get reset
    // on next tick back to empty value
    _resetVehicleConfig([]); // reset before setting - this ensure all clear...
    _pauseResetVehicleConfig.value = true;
    vehicleYear.value = yearId;
    vehicleMakes.value = await onLoadVehicleMakes(yearId);
    vehicleMake.value = makeName;
    vehicleModels.value = await onLoadVehicleModels(yearId, makeId);
    vehicleModel.value = modelName;
    vehicleTrims.value = await onLoadVehicleTrims(yearId, makeId, modelId);
    vehicleTrim.value = trimName;
    vehicleValues.value = {};
    nextTick(() => {
      // give everything a change to process - this can wait a moment
      _pauseResetVehicleConfig.value = false;
    });
  }

  /**
   * R4 Vehicle Search
   */
  // {
  //     "email": "brennan.brooks@lutherauto.com",
  //     "vin": "3VW217AU7HM071489",
  //     "vehicle_year": 2017,
  //     "vehicle_make": "VOLKSWAGEN",
  //     "vehicle_model": "Golf",
  //     "vehicle_series": "1.8 TSI SWolfsburgSESEL",
  //     "vehicle_trim": null,
  //     "last_visit": "2019-04-27",
  //     "had_sale": true,
  //     "had_service": true
  // }

  // async function r4VehicleSearch(searchParams: Partial<R4SearchParams>) {
  //   const resp = await onR4VehicleSearch(searchParams);
  //   if (resp.data.length > 0) {
  //     r4VehicleSearchResults.value = resp.data;
  //     return true;
  //   } else {
  //     r4VehicleSearchResults.value = [];
  //     return false;
  //   }
  // }

  // singleSelect Options have a categoryName. All options have a categoryGroup
  // a singleSelect option is one where you can only select one option from the category
  // e.g. Engine, Transmission, Drivetrain, Main Body Color
  const isSingleSelectOption = (option: VehicleOption) => {
    return !!option.categoryName;
  };

  /**
   * NOTE: this way of using watch is slightly different from the Builder one.
   * The big difference is pass in the actual object and not a string like you do in vue 2.
   * If you use the string, it throws a bunch of errors about not being reactive and is very confusing
   *
   * Also note when you use `ref` to make it reactive, you
   * have to assign via `variableName.value = 'abc123'` rather than
   * just `variableName = 'abc123'`
   */
  function lookupVehicleYearId(year: string) {
    return vehicleYears.value.find((yearData) => yearData.year === year);
  }
  function lookupVehicleMakeId(vehicleMake: string) {
    return vehicleMakes.value.find((makesData) => makesData.makeName === vehicleMake);
  }
  function lookupVehicleModelId(vehicleModel: string) {
    return vehicleModels.value.find((modelsData) => modelsData.modelName === vehicleModel);
  }
  function lookupVehicleTrimId(vehicleTrim: string) {
    return vehicleTrims.value.find((trimsData) => trimsData.trimName === vehicleTrim);
  }

  /**
   * Vehicle Value
   */

  // const pricesData = {
  //     "prices": [
  //         { "priceTypeId": 3, "priceTypeDisplay": "Trade-In Value Fair", "condition": "Fair", "mileageAdjustment": 6565, "optionAdjustment": 0, "configuredValue": 16371, "rangeLow": 15453, "rangeHigh": 17288 },
  //         { "priceTypeId": 4, "priceTypeDisplay": "Trade-In Value Good", "condition": "Good", "mileageAdjustment": 6565, "optionAdjustment": 0, "configuredValue": 17083, "rangeLow": 16137, "rangeHigh": 18028 },
  //         { "priceTypeId": 5, "priceTypeDisplay": "Trade-In Value Excellent", "condition": "Excellent", "mileageAdjustment": 6565, "optionAdjustment": 0, "configuredValue": 18435, "rangeLow": 17483, "rangeHigh": 19386 },
  //         { "priceTypeId": 109, "priceTypeDisplay": "Trade-In Value Very Good", "condition": "Very Good", "mileageAdjustment": 6565, "optionAdjustment": 0, "configuredValue": 17690, "rangeLow": 16762, "rangeHigh": 18617 }
  //     ],
  //     "valuationDate": "02/15/2023",
  //     "typicalMileage": 107878,
  //     "urls": {
  //         "price_advisor_fair_purchase_price_url": "https://pauc.syndication.kbb.com/priceadvisorusedcar/fairpurchaseprice?APIKey=b2f12643-d338-4c17-b3cf-8869da4a89b8&ZIPCode=74119&Mileage=2355&VehicleId=400633&DisplayAskingPrice=true&DisplaySRP=true&DisplayFPP=true&OptionIds=5960044,5960047,5960049,5960052,5960053,5960057,5960058,5960060,5960061,5960062,5960066,5960069,5960071,5960072,5960073,5960075,5960076,5960080,5960083,5960097,5960121,6531185,6531200,6531204,6531207,6531210,6531214,6531216,6531220,6531222,7357426", "price_advisor_tradein_url": "https://pauc.syndication.kbb.com/priceadvisorusedcar/tradein?APIKey=b2f12643-d338-4c17-b3cf-8869da4a89b8&ZIPCode=74119&Mileage=2355&VehicleId=400633&Condition=Excellent&DisplayTI=true&OptionIds=5960044,5960047,5960049,5960052,5960053,5960057,5960058,5960060,5960061,5960062,5960066,5960069,5960071,5960072,5960073,5960075,5960076,5960080,5960083,5960097,5960121,6531185,6531200,6531204,6531207,6531210,6531214,6531216,6531220,6531222,7357426"
  //     }
  // }

  const { vehicleValues } = storeToRefs(store);

  const valuationDate = computed(() => vehicleValues.value?.valuationDate);
  const valuationTypicalMileage = computed(() => vehicleValues.value?.typicalMileage);
  const valuationPriceByCondition = (condition: string) => {
    if (!vehicleValues.value?.prices) {
      return 0.0;
    }
    const price = vehicleValues.value?.prices.find((price: any) => price.condition === condition);
    if (!price) {
      throw new Error(`No price found for condition ${condition}`);
    }
    const result = { ...price, ...vehicleValues.value };
    delete result.prices;
    return result;
  };
  // difference between valuationCondition & vehicleCondition - vehicleCondition is the users answer to their vehicle condition
  // valuationConditions are the conditions that came back from the valuation service
  const valuationConditions = computed(() => vehicleValues.value?.prices.map((price: any) => price.condition));
  const valuationUrls = computed(() => vehicleValues.value?.urls || {});
  const valuationFairPurchasePriceUrl = computed(() => vehicleValues.value?.urls?.price_advisor_fair_purchase_price_url);
  const valuationTradeinUrl = computed(() => vehicleValues.value?.urls?.price_advisor_tradein_url);

  async function calculateVehicleValue() {
    logger.info("Calculating Vehicle Value");
    const offerDetails = await onLoadVehicleValues(
      vehicleDetails.value.vehicleId,
      vehicleFullConfiguredOptions.value.map((opt: VehicleOptionData) => opt.vehicleOptionId),
      mileage.value,
      postalCode.value
    );
    logger.debug("Received Vehicle Values, processing URLs", JSON.stringify(offerDetails, null, 2));
    offerDetails.urls = {
      price_advisor_fair_purchase_price_url: "https:" + _fairPriceAdvisorQuerySrc(),
      price_advisor_tradein_url: "https:" + _tradeinPriceAdvisorQuerySrc(),
    };
    logger.debug("Vehicle Value Calculation Complete", JSON.stringify(offerDetails, null, 2));
    vehicleValues.value = offerDetails;
  }

  function buildLutherBuysCarsMarketValuation(valuationOverrides = <MarketValuation>{}): MarketValuation {
    return {
      ...{
        name: MARKET_VALUATOR_LUTHERBUYSCARS,
        appraisalValue: valuationPrice.value,
        appraisalMessage: null,
        vehicleDescription: `${vehicleYear.value} ${vehicleMake.value} ${vehicleModel.value} ${vehicleTrim.value}`,
        additionalInfo: {},
        isComplete: true,
        errorMessage: null,
        marketValuationId: marketValuationId.value,
        vin: vehicleVin.value,
      },
      ...valuationOverrides,
    };
  }

  const marketValuationConfig = useMarketValuationConfig();
  const marketValuation = useMarketValuation();
  // TODO - evaluate if useMarketValuation should become a part of useVehicleModel
  //        in a way that we can access usemarketvalatuion from useVehicleModel
  //        may be able to mimic what happened before without use of a store...
  async function createLutherBuysCarsMarketValuation(valuation: MarketValuation) {
    const marketAvailability = await marketValuationConfig.fetchMarketValuationConfig(postalCode.value);
    if (!marketAvailability.lutherbuyscars_available) {
      try {
        const msg = `Valuation Unavailable for postal code ${postalCode.value}`;
        const unavailPayload = buildLutherBuysCarsMarketValuation({ errorMessage: msg, appraisalMessage: msg });
        logger.debug("createLutherBuysCars - Valuation not Available", JSON.stringify(unavailPayload, null, 2));
        marketValuation.createMarketValuation(unavailPayload);
      } catch (err) {
        logger.error("Market Valuation Create Error on Not Available", attrs.name, err);
      }
    } else {
      const resp = await marketValuation.createMarketValuation(valuation);
      marketValuation.lutherBuysCars.value = valuation;
      return resp;
    }
  }

  function _fairPriceAdvisorQuerySrc() {
    const host = "//pauc.syndication.kbb.com/priceadvisorusedcar/fairpurchaseprice?";
    const params = {
      APIKey: "b2f12643-d338-4c17-b3cf-8869da4a89b8",
      ZIPCode: postalCode.value,
      Mileage: mileage.value,
      VehicleId: vehicleDetails.value.vehicleId,
      // AskingPriceLabel: 'Asking Price Label',
      // AskingPriceOverride: 98765,
      DisplayAskingPrice: true,
      DisplaySRP: false, // typical listing price
      DisplayFPP: true, // Fair Purchase Price
    };
    const optionIds = vehicleFullConfiguredOptions.value.map((opt: VehicleOptionData) => opt.vehicleOptionId).join(",");

    return `${host}${new URLSearchParams(params).toString()}&OptionIds=${optionIds}`;
  }

  function _tradeinPriceAdvisorQuerySrc() {
    const host = "//pauc.syndication.kbb.com/priceadvisorusedcar/tradein?";
    const params = {
      APIKey: "b2f12643-d338-4c17-b3cf-8869da4a89b8",
      ZIPCode: postalCode.value,
      Mileage: mileage.value,
      VehicleId: vehicleDetails.value.vehicleId,
      Condition: condition.value.replaceAll(" ", ""), // api call doesn't like spaces (i.e. Very Good)
      DisplayTI: true,
    };
    const optionIds = vehicleFullConfiguredOptions.value.map((opt: VehicleOptionData) => opt.vehicleOptionId).join(",");
    return `${host}${new URLSearchParams(params).toString()}&OptionIds=${optionIds}`;
  }

  // helper
  function selectedOptionsInCategory(categoryGroupName: string, optionList: VehicleOptionData[]): VehicleOptionData[] {
    return optionList.filter((option) => {
      return option.categoryGroup === categoryGroupName;
    });
  }

  /**
   * Initialize vehicle Years as the starting point for reactivity
   */
  function loadVehicleYears(loggingPrefix: string) {
    return async () => {
      // each use of this component will trigger the mounted callback
      // check to see if years already populated so it doesn't reset everything
      logger.debug(`${loggingPrefix} useVehicleModel - checking if years already populated`);
      if (vehicleYears.value && vehicleYears.value.length === 0) {
        vehicleYears.value = (await onLoadVehicleYears()).reverse();
      } else {
        logger.debug(`${loggingPrefix} useVehicleModel - Vehicle Years populated, not fetching`);
      }
    };
  }

  /**
   * Vue Lifecycle Hooks
   */

  onMounted(loadVehicleYears("+onMounted"));
  onServerPrefetch(loadVehicleYears("+onServerPrefetch"));

  watch(vehicleYear, async (newVal, oldVal) => {
    logger.debug("vehicleYear changed", newVal, oldVal, newVal === oldVal);
    if (newVal === oldVal) {
      return;
    }
    if (vehicleYear.value && !_pauseResetVehicleConfig.value) {
      // reset down the chain
      vehicleMakes.value = await onLoadVehicleMakes(lookupVehicleYearId(vehicleYear.value)?.yearId);
      vehicleModels.value = [];
      vehicleTrims.value = [];
      _resetVehicleConfig([ResetVehicleKey.YEAR]);
    }
  });

  watch(vehicleMake, async (newVal, oldVal) => {
    logger.debug("vehicleMake changed", newVal, oldVal, newVal === oldVal);
    if (newVal === oldVal) {
      return;
    }

    logger.debug("vehicleMake changed", vehicleMake.value);
    if (vehicleMake.value && !_pauseResetVehicleConfig.value) {
      vehicleModels.value = await onLoadVehicleModels(
        lookupVehicleYearId(vehicleYear.value)?.yearId,
        lookupVehicleMakeId(vehicleMake.value)?.makeId
      );
      vehicleTrims.value = [];
      _resetVehicleConfig([ResetVehicleKey.YEAR, ResetVehicleKey.MAKE]);
    }
  });

  watch(vehicleModel, async (newVal, oldVal) => {
    logger.debug("vehicleModel changed", newVal, oldVal, newVal === oldVal);
    if (newVal === oldVal) {
      return;
    }

    logger.debug("vehicleModel changed", vehicleModel.value);
    if (vehicleModel.value && !_pauseResetVehicleConfig.value) {
      vehicleTrims.value = await onLoadVehicleTrims(
        lookupVehicleYearId(vehicleYear.value)?.yearId,
        lookupVehicleMakeId(vehicleMake.value)?.makeId,
        lookupVehicleModelId(vehicleModel.value)?.modelId
      );
      _resetVehicleConfig([ResetVehicleKey.YEAR, ResetVehicleKey.MAKE, ResetVehicleKey.MODEL]);
    }
  });

  watch(vehicleTrim, async (newVal, oldVal) => {
    logger.debug("vehicleTrim changed", newVal, oldVal, newVal === oldVal);
    if (newVal === oldVal) {
      return;
    }

    logger.debug("vehicleTrim changed", vehicleTrim.value);
    if (vehicleTrim.value) {
      _resetVehicleConfig([ResetVehicleKey.YEAR, ResetVehicleKey.MAKE, ResetVehicleKey.MODEL, ResetVehicleKey.TRIM]);
      const trimDetail = lookupVehicleTrimId(vehicleTrim.value);
      const vehicleApiData = await onLoadVehiclesByYMMT(trimDetail?.yearId, trimDetail?.makeId, trimDetail?.modelId, trimDetail?.trimId); // note - pulling 1st item from list
      vehicleDetails.value = vehicleApiData[0];
      // set all available options...
      vehicleAllAvailableOptions.value = await onLoadVehicleOptions(vehicleDetails.value.vehicleId);
      // set default (is typical) options taking into account this was a vin lookup
      // and the vinDecodedVehicleDetails has options specific to that vehicle which may or may not be typical...
      const typicalOptions = vehicleAllAvailableOptions.value.filter((opt) => opt.isTypical);
      if (vinDecodedVehicleDetails.value?.length > 0) {
        logger.debug("We have a vehicle by vin lookup - setting options from vin to override typical options");
        // get the matching trim specific to vin's vehicle trim - maybe more than one trim option
        const selectedVehicleWithTrim = vinDecodedVehicleDetails.value.find((v) => v.trimName === vehicleTrim.value);

        // override any typical options with the vin specific options for all single select options
        const vinSpecificAndTypicalOpts = typicalOptions.map((typicalOpt) => {
          if (isSingleSelectOption(typicalOpt)) {
            // check to see if we have an override from the vin lookup
            const vinOpt = selectedVehicleWithTrim.vehicleOptions?.find((opt) => opt.categoryName === typicalOpt.categoryName);
            return vinOpt || typicalOpt;
          } else {
            return typicalOpt;
          }
        });
        // now check to see if there are any general/non single select options that need to be added
        const vinGeneralOpts = (selectedVehicleWithTrim.vehicleOptions || []).filter((opt) => !isSingleSelectOption(opt));
        vinGeneralOpts.forEach((vinOpt) => {
          // check to see if we already have this option
          const existingOpt = vinSpecificAndTypicalOpts.find((opt) => opt.vehicleOptionId === vinOpt.vehicleOptionId);
          if (!existingOpt) {
            vinSpecificAndTypicalOpts.push(vinOpt);
          }
        });
        vehicleFullConfiguredOptions.value = vinSpecificAndTypicalOpts;
      } else {
        vehicleFullConfiguredOptions.value = typicalOptions;
      }
    }
  });

  function vinSpecificAndTypicalOptions(allOptions, vinDecodedVehicleOptions) {
    logger.debug("Processing vin specific options, if any");
    if (vinDecodedVehicleOptions.length > 0) {
      // get the matching trim specific to vin's vehicle trim - maybe more than one trim option
      const selectedVehicleWithTrim = vinDecodedVehicleDetails.value.find((v) => v.trimName === vehicleTrim.value);

      // override any typical options with the vin specific options for all single select options
      const vinSpecificAndTypicalOpts = typicalOptions.map((typicalOpt) => {
        if (isSingleSelectOption(typicalOpt)) {
          // check to see if we have an override from the vin lookup
          const vinOpt = selectedVehicleWithTrim.vehicleOptions?.find((opt) => opt.categoryName === typicalOpt.categoryName);
          return vinOpt || typicalOpt;
        } else {
          return typicalOpt;
        }
      });
      // now check to see if there are any general/non single select options that need to be added
      const vinGeneralOpts = (selectedVehicleWithTrim.vehicleOptions || []).filter((opt) => !isSingleSelectOption(opt));
      vinGeneralOpts.forEach((vinOpt) => {
        // check to see if we already have this option
        const existingOpt = vinSpecificAndTypicalOpts.find((opt) => opt.vehicleOptionId === vinOpt.vehicleOptionId);
        if (!existingOpt) {
          vinSpecificAndTypicalOpts.push(vinOpt);
        }
      });
    } else {
      logger.debug("No vin specific options found, returning all options");
      return allOptions;
    }
    // vehicleFullConfiguredOptions.value = vinSpecificAndTypicalOpts;
  }

  watch(_vehicleFullConfiguredOptions, async (newVal, oldVal) => {
    logger.debug("_vehicleFullConfiguredOptions changed - syncing options from configured");
    if (_vehicleFullConfiguredOptions.value) {
      // now we have to set the category options on vehicle so navigation behaves and
      // categories like engine & transmission show as initially selected w/o triggering
      // a page change automagically on that page loaded
      syncVehicleOptionsFromConfigured();
    }
  });

  // UTILITY FUNCTIONS - NOT EXPORTED...

  function buildComputedVehicleGetterSetter<T>(field: keyof T, additionalUpdateProps: Partial<T> = {}) {
    return {
      get() {
        logger.debug("getField", vehicleInstance.value[field]);
        return vehicleInstance.value[field];
      },
      set(value: T[keyof T]) {
        logger.debug("setField", field, value);
        vehicleInstance.value = {
          ...vehicleInstance.value,
          ...{ [field]: value, lastChange: new Date().valueOf() },
          ...additionalUpdateProps,
        };
      },
    };
  }

  async function syncVehicleOptionsFromConfigured() {
    const vehicleSingleOpts = Object.values(SingleCategoryMapping).reduce((accum, categoryMappingName) => {
      const categoryOpt = vehicleFullConfiguredOptions.value.find((opt: VehicleOptionData) => opt.categoryName === categoryMappingName);
      if (categoryOpt) {
        accum[SingleCategoryOptionKey[categoryMappingName]] = categoryOpt.optionName;
      } else {
        // option was de-selected
        accum[SingleCategoryOptionKey[categoryMappingName]] = "";
      }
      return accum;
    }, {});

    // build the general/non categoryName opts
    const vehicleOpts = vehicleFullConfiguredOptions.value
      .filter((opt: VehicleOptionData) => !opt.categoryName)
      .map((opt: VehicleOptionData) => opt.optionName);
    vehicleInstance.value = {
      ...vehicleInstance.value,
      ...vehicleSingleOpts,
      vehicle_options: vehicleOpts,
    };
  }

  enum ResetVehicleKey {
    YEAR = "vehicleYear",
    MAKE = "vehicleMake",
    MODEL = "vehicleModel",
    TRIM = "vehicleTrim",
    OPTIONS = "vehicleOptions",
  }

  const _pauseResetVehicleConfig = ref(false);
  function _resetVehicleConfig(keep: ResetVehicleKey[]) {
    logger.debug("Resetting Vehicle Config!", JSON.stringify(keep, null, 2));
    if (_pauseResetVehicleConfig.value) {
      logger.warn("resetVehicleConfig - paused");
      return;
    }
    logger.debug("resetting vehicle config", keep);
    if (!keep.includes(ResetVehicleKey.YEAR)) {
      vehicleYear.value = null;
    }
    if (!keep.includes(ResetVehicleKey.MAKE)) {
      vehicleMake.value = null;
    }

    if (!keep.includes(ResetVehicleKey.MODEL)) {
      vehicleModel.value = null;
    }

    if (!keep.includes(ResetVehicleKey.TRIM)) {
      vehicleTrim.value = null;
    }

    if (!keep.includes(ResetVehicleKey.OPTIONS)) {
      vehicleFullConfiguredOptions.value = []; // this resets the vehicle_options key on the vehicle instance
      const optionsKeys = Object.keys(vehicleInstance.value).filter((key) => key.startsWith("option_"));
      optionsKeys.forEach((key) => {
        vehicleInstance.value[key] = null;
      });
    }
  }

  return {
    workflowName,
    marketValuationId,
    selectedMarketValuation,
    customerName,
    customerEmail,
    customerPhone,
    /**
     * Vehicle Data YMM, Options, etc
     */
    vehicleYears,
    vehicleMakes,
    vehicleModels,
    vehicleTrims,
    vehicleConditions,
    vehicleDetails, // vehicle details from API
    vehicleAllAvailableOptions, // all available options for the vehicle in object form for lists
    vehicleAllAvailableGroupedOptions, // all available GROUPED options for the vehicle in object form for lists - same as above list but grouped
    availableColors,
    availableEngines,
    availableDrivetrains,
    availableTransmissions,
    yesNoAnswers, // util - reusable yes/no answers

    /**
     * Vehicle Instance & Accessors
     */
    vehicleInstance, // exported details of vehicle configuration
    vehicleVin,
    vehicleYear,
    vehicleMake,
    vehicleModel,
    vehicleTrim,

    /**
     * NOTE: below 4 optionXXX can be used to directly manipulate the vehicleInstance if you want to set directly
     * Did not have to use these in any components so far but they are here if needed
     * options in the demo UI are set via the optionXXXDetail and vehicleFullConfiguredOptions
     */
    optionEngine,
    optionTransmission,
    optionDrivetrain,
    optionColor,
    generalOptions: _generalOptions,

    /**
     * Vehicle Instance options object detail (aka kbb detail)
     * These will be used for UI selects
     */
    vehicleFullConfiguredOptions,
    optionColorDetail,
    optionDrivetrainDetail,
    optionEngineDetail,
    optionTransmissionDetail,

    /**
     * additional trade-in fields
     */
    mileage,
    postalCode,
    condition,
    accidentHistory,
    isUndrivable,
    defectsWarnings,
    exteriorDamage,
    salvageTitle,

    /**
     * Finance
     */

    FINANCE_TYPE_LOAN,
    FINANCE_TYPE_LEASE,
    FINANCE_TYPE_NO_LOAN,
    FINANCE_TYPE_MANUAL,
    financeType,
    financeTypes,

    financeTerm,
    financeMontlyPayment,
    financeLenderId,
    financeInquiryType,
    financeInquiryValue,
    financeInquiryTypes,
    financePayoffQuoteCustomerConsent,

    financeLenders,
    loadFinanceLenders,

    getPayoffQuote,
    financePayoffQuoteDetail,
    financePayoffGrossAmount,
    financePayoffNetAmount,
    financePayoffGoodThroughDate,

    valuationPrice,
    calculatedValuationPrice,

    /**
     * Lookup by plate
     */
    vehiclePlateNumber,
    vehiclePlateState,
    lookupVehicleByPlate,
    plateSearchResults: vinSearchResults, // plateSearchResults is the same as vinSearchResults - just an alias

    /**
     * Dms/R4 Vehicle Search
     */
    // r4VehicleSearch,
    // r4VehicleSearchResults,

    /**
     * By Vin
     */
    // findAndMatchVehicleByVinYMM: findAndMatchToKbbByVinYMTT,
    findVehicleByVin,
    vinSearchResults,
    setVehicle,

    /**
     * Vehicle Value
     */
    calculateVehicleValue,
    vehicleValues,
    valuationDate,
    valuationTypicalMileage,
    valuationPriceByCondition,
    valuationConditions,
    valuationUrls,
    valuationFairPurchasePriceUrl,
    valuationTradeinUrl,
    buildLutherBuysCarsMarketValuation,
    createLutherBuysCarsMarketValuation,

    /**
     * UTILS - validate & helpers for components
     */
    validateVehicleConfiguration,
    invalidOptions,
    selectedOptionsInCategory,
    isSingleSelectOption,
    isOptionConfigurationValid,

    /**
     * These are used to suppliment the YMMT selects
     * You can give any one of these YMMT data and get back
     * the full data for that YMMT from KBB
     * NOTE: you *only* use this when the year, make & model are loaded
     */
    lookupFullVehicleYearData: lookupVehicleYearId,
    lookupFullVehicleMakeData: lookupVehicleMakeId,
    lookupFullVehicleModelData: lookupVehicleModelId,
    lookupFullVehicleTrimData: lookupVehicleTrimId,
  };
};

export default useVehicleModel;
