import FuzzySet from "fuzzyset";
import { VALUATION_API_ENDPOINT } from "~/composables/useMarketValuations/constants";
import type { MarketValuation } from "~/composables/useMarketValuations/types";

type CarmaxVehicleStyle = {
  body: { code: string; text: string };
  cylinderCount: number;
  description: string;
  drive: { code: string; text: string };
  engineSize: { code: string; text: string };
  engineType: null;
  fuelType: { code: string; text: string };
  id: string;
  make: { code: string; text: string };
  model: { code: string; text: string };
  modelYear: number;
  transmission: { code: string; text: string };
  trim: { code: string; text: string };
};

type CarmaxVehicleBodyStyles = {
  matchingStyles: CarmaxVehicleStyle[];
  remainingStyles: CarmaxVehicleStyle[];
};

const CarMaxApi = {
  getBearerToken() {
    return $fetch("/carmax/token", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
    });
  },
  getVehicleInfo(data) {
    return $fetch("/carmax/vin", {
      method: "GET",
      query: { vin: data.vin, bearerToken: data.bearerToken },
      baseURL: VALUATION_API_ENDPOINT,
    });
  },

  getQuoteId(data) {
    return $fetch("/carmax/get_quote_id", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        vin: data.vin,
        year: data.year,
        make: data.make,
        model: data.model,
        zipcode: data.zipcode,
        bearerToken: data.bearerToken,
      },
    });
  },

  getBodyStyles(data): Promise<CarmaxVehicleBodyStyles> {
    return $fetch("/carmax/body_styles", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        vin: data.vin,
        bearerToken: data.bearerToken,
        years: data.modelYear,
        makes: data.makeCode,
        models: data.modelCode,
      },
    });
  },

  getVehicleStyleInfo(data) {
    return $fetch("/carmax/vehicle_style_info", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        styleId: data.styleId,
        bearerToken: data.bearerToken,
      },
    });
  },

  getConditionQuestions(data) {
    return $fetch("/carmax/condition_questions", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        bearerToken: data.bearerToken,
      },
    });
  },

  quoteOffer(data) {
    return $fetch("/carmax/quote_offer", {
      method: "POST",
      body: data,
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        bearerToken: data.bearerToken,
      },
    });
  },
  // VehicleList is an array of objects with the following properties:
  // {
  //   "matchingStyles": [
  //     {
  //       "body": { "code": "S006", "text": "4D Sport Utility" },
  //       "cylinderCount": 4,
  //       "description": "Premium 4D Sport Utility 2.0L",
  //       "drive": { "code": "4WD", "text": "4WD/AWD" },
  //       "engineSize": { "code": "0020", "text": "2.0L" },
  //       "engineType": null,
  //       "fuelType": { "code": "G001", "text": "Gas" },
  //       "id": "jpuehz7",
  //       "make": { "code": "SU", "text": "Subaru" },
  //       "model": { "code": "CRST", "text": "Crosstrek" },
  //       "modelYear": 2019,
  //       "transmission": { "code": "A", "text": "Automatic" },
  //       "trim": { "code": "PREM", "text": "Premium" }
  //     },
  //     ...
  //   ],
  //   "remainingStyles": [
  //     ...ListOfSameFieldsAsAbove
  //   ]
  // }
  matchVehicle(selectedTrim: string, vehicleStyles: CarmaxVehicleBodyStyles) {
    const styleSet = FuzzySet(
      [...vehicleStyles.matchingStyles, ...vehicleStyles.remainingStyles].map((style) => style.description),
      false, // don't use levenshtein distance - pickup trucks match better
      2,
      3
    );
    const match = styleSet.get(selectedTrim);
    if (match && match[0] && match[0][1]) {
      return [...vehicleStyles.matchingStyles, ...vehicleStyles.remainingStyles].find((style) => style.description === match[0][1]);
    } else {
      throw new Error(`Unable to match vehicle style: ${selectedTrim}`);
    }
  },

  // whey in the hell they put mileage under condition questions is beyond me
  toConditionAnswers(questions, mileage) {
    const answers = questions.reduce((accum, question) => {
      const ans = question.answers.find((answer) => answer.description === "No");
      if (!ans) {
        // might not be a yes/no question
        if (question.category === "Keys") {
          const keyAns = question.answers.find((answer) => answer.description.match(/2/));
          accum[question.id.toString()] = {
            // value include the number 2, i.e. "2 or more" keys
            value: keyAns.id.toString(),
            label: keyAns.description,
          };
        }
      } else {
        accum[question.id.toString()] = {
          value: question.answers.find((answer) => answer.description === "No").id.toString(),
          label: "No",
        };
      }

      return accum;
    }, {});

    return { ...answers, mileage: mileage };
  },

  generateValuationPayload({
    bearerToken,
    styleId,
    quoteId,
    vehicleFeaturesByStyle,
    selectedAvailableOptions,
    vin,
    stylesByVin,
    zipCode,
    conditionAnswers,
  }) {
    const vehicleFeatures = {};
    const stdOptions = [];
    const availableOptions = selectedAvailableOptions || [];

    Object.keys(vehicleFeaturesByStyle).forEach((key) => {
      if (key === "availableOptions") {
        // availableOptions.push(...vehicleFeaturesByStyle[key].map((option) => option.code));
        // do nothing
      } else if (key === "standardOptions") {
        stdOptions.push(...vehicleFeaturesByStyle[key].map((option) => option.code));
      } else {
        vehicleFeatures[key] = vehicleFeaturesByStyle[key];
      }
    });

    return {
      basicInfo: {
        style: styleId,
      },
      vehicleFeatures: vehicleFeatures,
      standardOptions: stdOptions,
      availableOptions: availableOptions,
      conditionInfo: conditionAnswers,
      customerInfo: {
        zipcode: zipCode,
      },
      bearerToken: bearerToken,
      quoteId: quoteId,
      vehicleFeaturesByStyle: vehicleFeaturesByStyle,
      vin: vin,
      stylesByVin: stylesByVin,
    };
  },
};

export async function fetchMarketValuation(vehicleModelComposable): Promise<MarketValuation> {
  const logger = useLogger("carmax");

  let valuation, valuationMessage, canValue, valuationResp, value;
  let bodyStyles, bodyStyleMatch, vehicleStyleInfo, conditionQuestions;

  const token = (await CarMaxApi.getBearerToken()).token;
  const vehicleInfo = await CarMaxApi.getVehicleInfo({
    vin: vehicleModelComposable.vehicleVin.value,
    bearerToken: token,
  });

  const vehicleDescription = `${vehicleInfo?.matches[0].modelYear} ${vehicleInfo?.matches[0].makeDescription} ${vehicleInfo?.matches[0].modelDescription} ${vehicleInfo?.matches[0].trimDescription}`;

  const quoteData = await CarMaxApi.getQuoteId({
    vin: vehicleModelComposable.vehicleVin.value,
    year: vehicleInfo?.matches[0].modelYear,
    make: vehicleInfo?.matches[0].makeCode,
    model: vehicleInfo?.matches[0].modelCode,
    zipcode: vehicleModelComposable.postalCode.value,
    bearerToken: token,
  });

  if (quoteData.isEligible === false) {
    canValue = false;
    valuation = 0;
    valuationMessage =
      "No Offer with Reason: Online offers are based on recent appraisals of similar cars, and we haven't seen many like yours lately. For your 7-day offer, stop by your local CarMax";
  } else {
    // continue with valuation
    bodyStyles = await CarMaxApi.getBodyStyles({
      vin: vehicleModelComposable.vehicleVin.value,
      bearerToken: token,
      ...vehicleInfo.matches[0],
    });

    bodyStyleMatch = CarMaxApi.matchVehicle(vehicleModelComposable.vehicleTrim.value, bodyStyles);

    vehicleStyleInfo = await CarMaxApi.getVehicleStyleInfo({
      styleId: bodyStyleMatch.id,
      bearerToken: token,
    });

    conditionQuestions = await CarMaxApi.getConditionQuestions({
      bearerToken: token,
    });

    // for carmax, we get all the selected options from the vehicleModelComposable, hash them in a FuzzySet, and then
    // match them against each of the available options
    const optionSet = FuzzySet(vehicleModelComposable.vehicleInstance.value.vehicle_options, false, 2, 3);
    logger.debug(">>> Option Matching: CarMax");
    const addlOptions = vehicleStyleInfo.availableOptions.filter((option) => {
      const match = optionSet.get(option.text);
      if (match && match[0]) {
        match.forEach((m) => {
          logger.debug(`>>> AO: ${option.text} | Score: ${m[0]} ${m[1]} | UseResult: ${m[0] >= 0.4}`);
        });
      } else {
        logger.debug(`>>> AO: ${option.text} | No Matches`);
      }
      return match && match[0] && match[0][1] && match[0][0] >= 0.4;
    });

    const payload = {
      quoteId: quoteData.quoteId,
      styleId: bodyStyleMatch.id,
      vin: vehicleModelComposable.vehicleVin.value,
      vehicleFeaturesByStyle: vehicleStyleInfo, // TODO - make this the filtered additional options
      selectedAvailableOptions: addlOptions.map((option) => option.code),
      stylesByVin: bodyStyles,
      bearerToken: token,
      zipCode: vehicleModelComposable.postalCode.value.toString(),
      conditionAnswers: CarMaxApi.toConditionAnswers(conditionQuestions, vehicleModelComposable.mileage.value.toString()),
    };

    logger.debug("x>>> Valuation Payload: CarMax", JSON.stringify(CarMaxApi.generateValuationPayload(payload), null, 2));

    valuationResp = await CarMaxApi.quoteOffer(CarMaxApi.generateValuationPayload(payload));

    valuation = valuationResp?.offer?.offerAmount;
    value = (valuationResp?.offer?.offerAmount || 0) === 0 ? "No Offer" : "Offer";
    valuationMessage = (valuationResp?.offer?.offerAmount || 0) === 0 ? "No Offer" : "Offer";
  }

  return {
    appraisalValue: valuation,
    appraisalMessage: valuationMessage,
    vehicleDescription: vehicleDescription,
    additionalInfo: {
      canValue: canValue,
      valuationResp: valuationResp || null,
      vehicleInfo: vehicleInfo,
      bodyStyles: bodyStyles,
      quoteData: quoteData,
      bodyStyleMatch: bodyStyleMatch,
      vehicleStyleInfo: vehicleStyleInfo,
      conditionQuestions: conditionQuestions,
    },
  };
}
