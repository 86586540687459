import FuzzySet from "fuzzyset";
import { VALUATION_API_ENDPOINT } from "~/composables/useMarketValuations/constants";
import type { MarketValuation } from "~/composables/useMarketValuations/types";

type MorriesColors = {
  id: string;
  name: string;
};

const MorriesApi = {
  lookupVin(vin: string) {
    return $fetch("/morries/body_type", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        vin: vin,
      },
    });
  },

  consumerQuestion() {
    return $fetch("/morries/consumer_question", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
    });
  },

  setOptions(data) {
    return $fetch("/morries/select_options", {
      method: "POST",
      baseURL: VALUATION_API_ENDPOINT,
      body: data,
    });
  },

  // firstStep(data) {
  //   return $fetch("/morries/select_options", {
  //     method: "POST",
  //     baseURL: VALUATION_API_ENDPOINT,
  //     body: data,
  //   });
  // },

  // secondStep(data) {
  //   return $fetch("/morries/select_options", {
  //     method: "POST",
  //     baseURL: VALUATION_API_ENDPOINT,
  //     body: data,
  //   });
  // },

  // thirdStep(data) {
  //   return $fetch("/morries/select_options", {
  //     method: "POST",
  //     baseURL: VALUATION_API_ENDPOINT,
  //     body: data,
  //   });
  // },

  getPrice(data) {
    return $fetch("/morries/valuation", {
      method: "POST",
      baseURL: VALUATION_API_ENDPOINT,
      body: data,
    });
  },

  // WTF is this hackery of a UUID Generator?
  // ported direct from the original code
  uuid() {
    // Generate a UUID
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    // Replace the 13th character with 'a', and the 23rd character with 'c'
    const preparedId = uuid.split("");
    preparedId[12] = "a";
    preparedId[22] = "c";
    return preparedId.join("");
  },

  matchColor(color: string, colors: MorriesColors[]) {
    const colorSet = FuzzySet(colors.map((c) => c.name));
    const match = colorSet.get(color);
    if (match && match[0] && match[0][1]) {
      return colors.find((c) => c.name === match[0][1]).id;
    } else {
      // just return a color if we can't find a match, close enough...
      return colors[0].id;
    }
  },
  mapOptions(composableOptions, morriesOptions) {
    const selectedOptions = [];
    const optionSet = FuzzySet(
      composableOptions.map((c) => c.optionName),
      false,
      2,
      3
    );

    for (const option of morriesOptions) {
      const match = optionSet.get(option.name);
      if (match && match[0] && match[0][1] && match[0][0] >= 0.5) {
        // logger.debug("Found Matching Option: ", option.name, " => ", match[0][1], "Score", match[0][0]);
        selectedOptions.push(option.id);
      }
    }

    return selectedOptions;
  },
  buildVehicleConditions(mileage, conditionQuestions) {
    const conditions = conditionQuestions?.conditionReport?.categories?.map((category) =>
      category.questions.map((question) => {
        const condition = {
          [question.id]:
            question.responseOptions.find((option) => option.isPositive)?.id ??
            (question.responseOptions.map((option) => option.id).includes("0") ? "0" : "N"),
        };
        return condition;
      })
    );

    const flattenedConditions = Object.assign({ mileage: String(mileage) }, ...conditions.flat());
    return flattenedConditions;
  },
};

export async function fetchMarketValuation(vehicleModelComposable): Promise<MarketValuation> {
  const logger = useLogger("morries");

  const vinResult = await MorriesApi.lookupVin(vehicleModelComposable.vehicleVin.value);
  const conditionQuestions = await MorriesApi.consumerQuestion();

  const basicInfoData = {
    styleId: vinResult.styleDetails?.styles[0]?.id,
    exteriorColor: MorriesApi.matchColor(vehicleModelComposable.optionColor.value, vinResult.genericExteriorColors),
    options: MorriesApi.mapOptions(vehicleModelComposable.generalOptions.value, vinResult.styleDetails?.styles[0]?.options),
  };

  const prospectId = MorriesApi.uuid();
  const vehicleConditionPayload = MorriesApi.buildVehicleConditions(vehicleModelComposable.mileage.value, conditionQuestions);

  const selectOptionData = {
    dealer:
      "LR_PGE+PGI+bHdhVEZNakd0eGoweGZHaERidEJzZz09PC9iPjxjPllCR0NqVTFBU25UT2hBT3lQN2tMODVGYVlrOGNZVFVMOVZ1ZjVQd08wZ29ZQnhGOEFhYThjd3FDaC9PSk42dFlaNUNhRUxXS0xHMVdGK2ZERGd6eXBweEtEWDZoQUJ6L254blhMK3pLcExOajRyUWFSVSthaE9pM000SmZJcSt0bDFHalViUDVzdkhqR3dzYk5oNjh4WWJjWE0xM2R5eTA1Y0pCbkVTT1l1dEZYZ2FOeXVDWlFwdVp6bVdBT0lLSG5CNWJwdGVVS1BrZWM4V1VKRkhyQ01Hd2YrRjRKQmR2WnA2ZkZYa3h2TElTY0RXYk1yK0tqdnVGNUdyVEt3S0RIS2IrTGJlVFBhaldGNWk0aHpzOUVpRjlOZ1ZQS0o3a0JtbzVhV0RFc0FOS1dub01FRVM3WXE3clpRcXk3TzMzSCt0WHVjTGFNbmlzaWlwN0FQN0JhZz09PC9jPjxkPnlRUzVSSlNuQ2RGUWduK0VYTTIwOVE9PTwvZD48L2E+",
    prospectUid: prospectId,
    infoData: vinResult,
    conditionQuestions: conditionQuestions?.conditionReport?.categories, // conditionQuestions,
    basicInfo: basicInfoData,
    vehicleCondition: vehicleConditionPayload,
    contactInfo: {},
  };

  logger.debug("Select Options Data: ", JSON.stringify(selectOptionData, null, 2));

  // the next 4 steps all save incrementally to the server via POST setOptions
  const vehicleInfoOptions = await MorriesApi.setOptions({ ...selectOptionData, ...{ consumerStep: "VEHICLE_INFO" } });
  logger.debug("Vehicle Info Options Result: ", JSON.stringify(vehicleInfoOptions, null, 2));

  const vehicleFeatureOptions = await MorriesApi.setOptions({
    ...selectOptionData,
    ...{ consumerStep: "VEHICLE_FEATURE" },
    dealer: vehicleInfoOptions.updatedDealer,
  });
  logger.debug("Vehicle Feature Options Result: ", JSON.stringify(vehicleFeatureOptions, null, 2));

  const vehicleConditionOptions = await MorriesApi.setOptions({
    ...selectOptionData,
    ...{ consumerStep: "VEHICLE_CONDITION" },
    dealer: vehicleFeatureOptions.updatedDealer,
  });
  logger.debug("Vehicle Condition Options Result: ", JSON.stringify(vehicleConditionOptions, null, 2));

  const valuationData = {
    prospectUid: prospectId,
    basicInfo: basicInfoData,
    vehicleCondition: vehicleConditionPayload,
    consumerStep: "VALUATION",
    dealer: vehicleConditionOptions.updatedDealer,
    vin: vehicleModelComposable.vehicleVin.value,
    contactInfo: {},
  };

  const valuationResult = await MorriesApi.getPrice({ ...selectOptionData, ...valuationData });

  let valuation, valuationMessage;

  if (valuationResult?.isOfferAvailable) {
    valuation = valuationResult?.consumerRequest?.finalOffer?.offerValue;
    valuationMessage = "";
  } else {
    valuation = 0;
    valuationMessage = `We were unable to value this vehicle. ${valuationResult?.noOfferReason}`;
  }

  const vehicleDescription = `${vinResult.year} ${vinResult.make} ${vinResult.model} ${vinResult.trim}`;

  return {
    appraisalValue: valuation,
    appraisalMessage: valuationMessage,
    vehicleDescription,
    additionalInfo: {
      vinResult,
      conditionQuestions,
      valuationResult,
    },
  };
}
