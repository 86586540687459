import FuzzySet from "fuzzyset";
import { difference } from "ramda";
import type { MarketValuation } from "~/composables/useMarketValuations/types";

import type { VinArgs, ValuationInput } from "~/composables/useMarketValuations/types";
import { VALUATION_API_ENDPOINT } from "~/composables/useMarketValuations/constants";

const carvanaColors = [
  { label: "Black", value: 0 },
  { label: "Gray", value: 1 },
  { label: "Silver", value: 2 },
  { label: "White", value: 3 },
  { label: "Blue", value: 4 },
  { label: "Purple", value: 5 },
  { label: "Pink", value: 6 },
  { label: "Red", value: 7 },
  { label: "Maroon", value: 8 },
  { label: "Brown", value: 9 },
  { label: "Beige", value: 10 },
  { label: "Gold", value: 11 },
  { label: "Orange", value: 12 },
  { label: "Yellow", value: 13 },
  { label: "Green", value: 14 },
  { label: "Other", value: 15 },
];

const carvanaConditions = [
  { label: "Excellent", value: "Great" },
  { label: "Very Good", value: "Great" },
  { label: "Good", value: "OKAY" },
  { label: "Fair", value: "Rough" },
];

const matchCarvanaColor = (color: string) => {
  const colorSet = FuzzySet(carvanaColors.map((c) => c.label));
  const match = colorSet.get(color);
  if (match && match[0] && match[0][1]) {
    return carvanaColors.find((c) => c.label === match[0][1]).value;
  }
  // just return a color if we can't find a match, close enough...
  return carvanaColors[0].value;
};

const matchCarvanaCondition = (condition: string) => {
  const conditionSet = FuzzySet(carvanaConditions.map((c) => c.label));
  const match = conditionSet.get(condition);
  if (match && match[0] && match[0][1]) {
    return carvanaConditions.find((c) => c.label === match[0][1]).value;
  }
};

const CarvanaApi = {
  lookupVin(data: VinArgs) {
    return $fetch("/carvana/body_type", {
      method: "GET",
      query: { vin: data.vin_number },
      baseURL: VALUATION_API_ENDPOINT,
    });
  },

  getConditionQuestions() {
    return $fetch("/carvana/condition_questions", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
    });
  },

  // getAutocheckReport(data) {
  //   return useFetch(`${VALUATION_API_ENDPOINT}/carvana/autocheck_report?vin=${data.vin_number}`);
  // },

  getVehicleOptionFeaturesByStyle(data) {
    return $fetch("/carvana/vehicle_option_features", {
      method: "GET",
      query: { id: data.kbbId },
      baseURL: VALUATION_API_ENDPOINT,
    });
  },

  getValuation(data) {
    return $fetch("/carvana/valuation", {
      method: "POST",
      body: data,
      baseURL: VALUATION_API_ENDPOINT,
    });
  },

  generateValuationPayload(valuationInput: ValuationInput) {
    return {
      vin: valuationInput.vin, // input
      basicInfo: {
        trimId: valuationInput.vehicleId, // this is the vehicle id, not trim id
        color: matchCarvanaColor(valuationInput.color),
        transmission: valuationInput.transmissionOptionId, // same as kbb
        drivetrain: valuationInput.drivetrainOptionId, // same as kbb
        engine: valuationInput.engineOptionId, // same as kbb
      },
      vehicleCondition: {
        mileage: valuationInput.mileage, // input
        accidentCount: 0,
        drivable: true,
        mechanicalDefectsIssues: ["none"],
        exteriorDamageIssues: ["none"],
        interiorDamageIssues: ["none"],
        hasAftermarketParts: false,
        hasBeenSmokedIn: false,
        keyCount: 2,
        physicalCondition: matchCarvanaCondition(valuationInput.condition),
      },
      contactInfo: {
        zipCode: String(valuationInput.zipCode), // input
      },
      vehicleFeatures: {
        // add all options under any key - backend only looks at the values of the keys
        vehicleOptions: valuationInput.optionIds, // input
        // EntertainmentandInstrumentation: [2267065],
        // Seats: [2267076],
        // RoofandGlass: [2267079],
        // Exterior: [2267081],
        // WheelsandTires: [2267082],
      },
      // this is a straight pass through value from the lookup vin endpoint
      vehicleInfo: valuationInput.vehicleInfo,
    };
  },
};

export async function fetchMarketValuation(vehicleModelComposable): Promise<MarketValuation> {
  const logger = useLogger("carvana");

  const vehicleResp = await CarvanaApi.lookupVin({ vin_number: vehicleModelComposable.vehicleVin.value });

  const vehicleDescription = `${vehicleResp[0].displayName} ${vehicleResp[0].makes[0].displayName} ${vehicleResp[0].makes[0].models[0].displayName} ${vehicleResp[0].makes[0].models[0].displayName} ${vehicleResp[0].makes[0].models[0].trims[0].displayName}`;

  const questionsResp = await CarvanaApi.getConditionQuestions();

  const defaultOptionsList = await CarvanaApi.getVehicleOptionFeaturesByStyle({
    kbbId: vehicleModelComposable.vehicleDetails.value.vehicleId,
  });
  const selectedOptions = vehicleModelComposable.generalOptions.value.map((o) => o.vehicleOptionId);

  const defaultOptions = defaultOptionsList.reduce((acc, optionGroup) => {
    optionGroup.groupedOptions.forEach((option) => {
      if (option.isDefault) {
        acc.push(option.optionId);
      }
    });
    return acc;
  }, []);

  const carvanaOptions = difference(selectedOptions, defaultOptions);

  const payload = CarvanaApi.generateValuationPayload({
    vin: vehicleModelComposable.vehicleVin.value,
    vehicleId: vehicleModelComposable.vehicleDetails.value.vehicleId,
    // if from carvana quote... vehicleResp[0].makes[0].models[0].trims[0].kbbId,
    color: vehicleModelComposable.optionColor.value,
    transmissionOptionId: vehicleModelComposable.optionTransmissionDetail.value[0].vehicleOptionId,
    drivetrainOptionId: vehicleModelComposable.optionDrivetrainDetail.value[0].vehicleOptionId,
    engineOptionId: vehicleModelComposable.optionEngineDetail.value[0].vehicleOptionId,
    mileage: vehicleModelComposable.mileage.value,
    condition: vehicleModelComposable.condition.value,
    optionIds: carvanaOptions,
    zipCode: vehicleModelComposable.postalCode.value,
    vehicleInfo: vehicleResp,
  });

  logger.info("API Request", JSON.stringify(payload, null, 2));
  const valuationResp = await CarvanaApi.getValuation(payload);

  return {
    appraisalValue: valuationResp?.data?.createAppraisal?.offerValue || 0,
    appraisalMessage: null,
    vehicleDescription,
    additionalInfo: {
      vehicle: vehicleResp,
      questions: questionsResp,
      features: defaultOptionsList,
      valuation: valuationResp,
    },
  };
}
