import type MarketValuationRouting from "~/types/db/MarketValuationRouting";

export const useMarketValuationConfig = () => {
  const logger = useLogger("useMarketValuation");
  async function fetchMarketValuationConfig(postalCode: string): Promise<MarketValuationRouting> {
    const resp = await $fetch("/api/v1/valuations/availability", {
      method: "POST",
      body: { postalCode },
    });
    const filteredEmailKeys = Object.keys(resp)
      .filter((k) => !k.includes("_email"))
      .reduce((accum, key) => {
        accum[key] = resp[key];
        return accum;
      }, {});
    return filteredEmailKeys as MarketValuationRouting;
  }

  return {
    fetchMarketValuationConfig: fetchMarketValuationConfig,
  };
};

export default useMarketValuationConfig;
