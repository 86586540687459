import FuzzySet from "fuzzyset";
import { VALUATION_API_ENDPOINT } from "~/composables/useMarketValuations/constants";
import type { MarketValuation } from "~/composables/useMarketValuations/types";

const WeBuyAnyCarApi = {
  async lookupVin(vin) {
    const payload = {
      valuationMethod: 2,
      plate: { plateNumber: null, state: null },
      vin: {
        enteredVin: vin,
        attemptedVins: [vin],
        vinWasCorrected: false,
      },
      yearMakeModel: { year: "", make: "", model: "" },
    };

    const resp = await $fetch("/webuyanycar/vin/get_vin", {
      method: "POST",
      baseURL: VALUATION_API_ENDPOINT,
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (typeof resp === "string") {
      // wtf - did the double escape it?
      return JSON.parse(resp);
    } else {
      return resp;
    }
  },
  fetchBodyType(customerJourneyId) {
    return $fetch("/webuyanycar/step/body_type", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
      query: {
        customerJourneyId: customerJourneyId,
      },
    });
  },
  fetchVehicleDetails(data) {
    return $fetch("/webuyanycar/vehicle_detail_dropdowns", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
      query: {
        customerJourneyId: data.customerJourneyId,
        selectedSeries: data.selectedSeries,
        selectedBodyStyle: data.selectedBodyStyle,
      },
    });
  },

  setBodyConfig(data) {
    return $fetch("/webuyanycar/step/body_type", {
      method: "POST",
      baseURL: VALUATION_API_ENDPOINT,
      body: data,
    });
  },
  setConditions(data) {
    return $fetch(`/webuyanycar/step/conditions`, {
      body: data,
      method: "POST",
      baseURL: VALUATION_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  calculateValuation(data) {
    return $fetch("/webuyanycar/step/valuation", {
      method: "GET",
      baseURL: VALUATION_API_ENDPOINT,
      query: {
        customerJourneyId: data.customerJourneyId,
      },
    });
  },
  mapBodyStyle(trim, webuyBodyTypeResult) {
    if ((webuyBodyTypeResult?.BodyTypes || []).length === 0) {
      // if no BodyTypes array, then it's a single body type specified in BodyStyle
      return webuyBodyTypeResult.BodyStyle;
    } else {
      const bodyTypeSet = FuzzySet(webuyBodyTypeResult.BodyTypes, false, 2, 3);
      const bodyTypeMatch = bodyTypeSet.get(trim);
      if (bodyTypeMatch && bodyTypeMatch[0] && bodyTypeMatch[0][1]) {
        return bodyTypeMatch[0][1];
      } else {
        // NOTE: return the first one
        return webuyBodyTypeResult.BodyTypes[0];
      }
    }
  },
  mapEngineDetails(engine, vehicleDetails) {
    if (vehicleDetails.engine_options.length === 0) {
      return;
    } else if (vehicleDetails.engine_options.length === 1) {
      return vehicleDetails.engine_options[0];
    } else {
      // no idea if this is correct - have not found a vehicle with multiple engine options
      const engineSet = FuzzySet(vehicleDetails.engine_options);
      const engineMatch = engineSet.get(engine);
      if (engineMatch && engineMatch[0] && engineMatch[0][1]) {
        return engineMatch[0][1];
      } else {
        // Give up - just pick the first one
        return vehicleDetails.engine_options[0];
      }
    }
  },
};

export async function fetchMarketValuation(vehicleModelComposable): Promise<MarketValuation> {
  const logger = useLogger("webuyanycar");

  const vinResult = await WeBuyAnyCarApi.lookupVin(vehicleModelComposable.vehicleVin.value);
  const customerJourneyId = vinResult.customerJourneyId;
  const bodyTypeResult = await WeBuyAnyCarApi.fetchBodyType(customerJourneyId);

  const wbacSeries = bodyTypeResult.Series;

  // NOTE: haven't been able to solve for 5 3/4 or 6 1/2 foot bed in KBB vs Short Body or Long Body in WBAC or Carmax
  const wbacBodyStyle = WeBuyAnyCarApi.mapBodyStyle(vehicleModelComposable.vehicleTrim.value, bodyTypeResult);

  const vehicleDetails = await WeBuyAnyCarApi.fetchVehicleDetails({
    customerJourneyId,
    selectedSeries: wbacSeries,
    selectedBodyStyle: wbacBodyStyle,
  });

  const wbacEngine = WeBuyAnyCarApi.mapEngineDetails(vehicleModelComposable.optionEngine.value, vehicleDetails);

  let bodyConfigPayload = {
    ModelYear: bodyTypeResult.ModelYear,
    Manufacturer: bodyTypeResult.Manufacturer,
    ModelName: bodyTypeResult.ModelName,
    DriveableQuestionSplitTestIsActive: true,
    CustomerJourneyId: customerJourneyId,
    Series: bodyTypeResult.Series,
    BodyStyle: wbacBodyStyle,
    Engine: wbacEngine,
  };

  const removeNullUndefined = (obj) => Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});

  const setBodyConfigPayload = removeNullUndefined(bodyConfigPayload);

  logger.debug("setBodyConfigPayload", JSON.stringify(setBodyConfigPayload, null, 2));
  const setBodyConfigResp = await WeBuyAnyCarApi.setBodyConfig(setBodyConfigPayload);

  const conditionPayload = {
    CustomerJourneyId: customerJourneyId,
    ModelYear: bodyTypeResult.ModelYear,
    Manufacturer: bodyTypeResult.Manufacturer,
    ModelName: bodyTypeResult.ModelName,
    Series: bodyTypeResult.Series,
    BodyStyle: wbacBodyStyle,
    VehicleDisplayName: `${bodyTypeResult.ModelYear} ${bodyTypeResult.Manufacturer} ${bodyTypeResult.ModelName}`,
    Mileage: vehicleModelComposable.mileage.value,
    ZipCode: vehicleModelComposable.postalCode.value,
    BeenInAnAccident: "False", // HardCoded
    HasCosmeticOrMechanicalIssues: "False", // HardCoded
    ClearTitle: "True", // HardCoded
    // Email: values.condition.Email,
    // OptionalPhoneNumber: values.condition.OptionalPhoneNumber,
  };

  const conditionResp = await WeBuyAnyCarApi.setConditions(conditionPayload);

  const valuationResp = await WeBuyAnyCarApi.calculateValuation({ customerJourneyId: customerJourneyId });

  let valuation;
  let valuationMessage = null;
  if (valuationResp?.price && valuationResp?.price.includes("don't have enough information about it")) {
    valuationMessage = "No offer with reason: Vehicle is so new or we don't have enough information about it.";
  } else {
    // return the value as a number
    valuation = (valuationResp?.price || "0").replace(/[^0-9.-]+/g, "");
  }

  const vehicleDescription = `${bodyTypeResult.ModelYear} ${bodyTypeResult.Manufacturer} ${bodyTypeResult.ModelName} ${bodyTypeResult.Series}`;

  return {
    appraisalValue: parseInt(valuation),
    appraisalMessage: null,
    vehicleDescription,
    additionalInfo: {
      valuation: valuationResp,
      value: valuation,
      wbacSeries,
      wbacBodyStyle: wbacBodyStyle,
      wbacEngine,
      vinResult,
      bodyTypeResult,
      vehicleDetails,
      setBodyConfigResp,
      conditionResp,
      valuationResp,
    },
  };
}
