import * as Carvana from "./useMarketValuations/carvana";
import * as CarMax from "./useMarketValuations/carmax";
import * as WeBuyAnyCar from "./useMarketValuations/webuyanycar";
import * as CarGurus from "./useMarketValuations/morries";
import * as KbbInstantCash from "./useMarketValuations/kbbInstantCash";
import type { MarketValuations, MarketValuation } from "./useMarketValuations/types";

import {
  MARKET_VALUATOR_CARVANA,
  MARKET_VALUATOR_CARMAX,
  MARKET_VALUATOR_CARGURUS,
  MARKET_VALUATOR_WEBUYANYCAR,
  MARKET_VALUATOR_KBBINSTANTCASH,
  MARKET_VALUATOR_LUTHERBUYSCARS,
} from "@/lib/constants";

export const useMarketValuation = () => {
  const logger = useLogger("useMarketValuation");

  function buildValuationModel(modelName: string): Ref<MarketValuation> {
    return ref({
      name: modelName,
      appraisalValue: null,
      appraisalMessage: null,
      vehicleDescription: null,
      additionalInfo: null,
      isComplete: false,
      errorMessage: null,
      marketValuationId: null,
      vin: null,
    });
  }

  const carvana = buildValuationModel(MARKET_VALUATOR_CARVANA);
  const carmax = buildValuationModel(MARKET_VALUATOR_CARMAX);
  const cargurus = buildValuationModel(MARKET_VALUATOR_CARGURUS);
  const webuyanycar = buildValuationModel(MARKET_VALUATOR_WEBUYANYCAR);
  const kbbInstantCash = buildValuationModel(MARKET_VALUATOR_KBBINSTANTCASH);
  const lutherBuysCars = buildValuationModel(MARKET_VALUATOR_LUTHERBUYSCARS);

  const MarketValuators = {
    carvana: { valuator: Carvana, model: carvana, name: MARKET_VALUATOR_CARVANA, availability_key: "carvana_available" },
    carmax: { valuator: CarMax, model: carmax, name: MARKET_VALUATOR_CARMAX, availability_key: "carmax_available" },
    cargurus: { valuator: CarGurus, model: cargurus, name: MARKET_VALUATOR_CARGURUS, availability_key: "cargurus_available" },
    webuyanycar: {
      valuator: WeBuyAnyCar,
      model: webuyanycar,
      name: MARKET_VALUATOR_WEBUYANYCAR,
      availability_key: "webuyanycar_available",
    },
    kbbInstantCash: {
      valuator: KbbInstantCash,
      model: kbbInstantCash,
      name: MARKET_VALUATOR_KBBINSTANTCASH,
      availability_key: "kbbinstantcash_available",
    },
    lutherBuysCars: {
      valuator: null, // lutherBuys is a special case and does not have a valuator
      model: lutherBuysCars,
      name: MARKET_VALUATOR_LUTHERBUYSCARS,
      availability_key: "lutherbuyscars_available",
    },
  };

  async function createMarketValuation(valuation: any) {
    const response = await $fetch("/api/v1/valuations", {
      method: "POST",
      body: valuation,
    });
    return response;
  }
  async function loadMappedMarketValuations(marketValuationId: string) {
    const result = await $fetch(`/api/v1/valuations/${marketValuationId}`);
    return result.map((dbValuation) => {
      return {
        name: dbValuation.valuator_name,
        appraisalValue: dbValuation.appraisal_value,
        appraisalMessage: dbValuation.appraisal_message,
        vehicleDescription: dbValuation.vehicle_description,
        additionalInfo: dbValuation.additional_info,
        isComplete: dbValuation.is_complete,
        errorMessage: dbValuation.error_message,
        marketValuationId: dbValuation.market_valuation_id,
        vin: dbValuation.vin,
      };
    });
  }

  async function loadMarketValuations(marketValuationId: string) {
    try {
      const valuations: Array<MarketValuation> = await loadMappedMarketValuations(marketValuationId);
      return valuations;
    } catch (error) {
      logger.error("Error fetching market valuations", error);
      // TODO - handle error
    }
  }

  const loadingComplete = computed(() => {
    return [carvana, carmax, cargurus, webuyanycar, kbbInstantCash, lutherBuysCars].every((v) => {
      return v.value.isComplete;
    });
  });

  async function fetchMarketValuations(vehicleModelComposable): Promise<MarketValuations> {
    const marketValuationConfig = useMarketValuationConfig();
    const vin = unref(vehicleModelComposable.vehicleVin.value);
    logger.debug("FetchMarketValuationVin:", vin);

    // NOTE: do not use vehicleModelComposable.marketValuationId.value directly in the promises
    //       because it could possibly change by the time the promises are resolved
    const targetMarketValuationId = unref(vehicleModelComposable.marketValuationId.value);
    const targetPostalCode = unref(vehicleModelComposable.postalCode.value);
    const targetMarketAvailability = await marketValuationConfig.fetchMarketValuationConfig(targetPostalCode);

    const results = await Promise.all(
      [carvana, carmax, cargurus, webuyanycar, kbbInstantCash].map((valuator) => {
        // check to see if we should run the market valuation for this valuator based on the postal code
        const marketValuator = Object.values(MarketValuators).find((mv) => mv.name === valuator.value.name);
        if (!targetMarketAvailability[marketValuator?.availability_key]) {
          return new Promise((resolve) => {
            const unavailPayload = {
              errorMessage: `Valuation Unavailable for postal code ${targetPostalCode}`,
              isComplete: true,
              name: marketValuator.name,
              vin: vin,
              marketValuationId: targetMarketValuationId, //vehicleModelComposable.marketValuationId.value,
              appraisalValue: null,
              appraisalMessage: `Valuation Unavailable for postal code ${targetPostalCode}`,
              vehicleDescription: null,
              additionalInfo: null,
            };
            try {
              logger.debug("CreateValuationWithError", JSON.stringify(unavailPayload, null, 2));
              createMarketValuation(unavailPayload);
              valuator.value = unavailPayload as MarketValuation;
              // attrs.model.value = unavailPayload as MarketValuation;
            } catch (err) {
              logger.error("Market Valuation Create Error on Not Available", marketValuator.name, err);
            }
          });
        } else {
          // run the market valuation
          return new Promise((resolve) => {
            // 1st - create a non-complete market valuation record so we can poll properly
            const incompleteValuation = {
              errorMessage: null,
              isComplete: false,
              name: marketValuator.name,
              vin: vin,
              marketValuationId: targetMarketValuationId, //vehicleModelComposable.marketValuationId.value,
              appraisalValue: null,
              appraisalMessage: null,
              vehicleDescription: null,
              additionalInfo: null,
            };
            try {
              logger.debug("CreateValuationInitialNotComplete", JSON.stringify(incompleteValuation, null, 2));
              createMarketValuation(incompleteValuation);
            } catch (err) {
              logger.error("Market Valuation Create Initial Incomplete", marketValuator.name, err);
            }

            marketValuator.valuator
              .fetchMarketValuation(vehicleModelComposable)
              .then((result) => {
                const marketValuationData = {
                  ...result,
                  isComplete: true,
                  name: marketValuator.name,
                  errorMessage: null,
                  vin: vin,
                  marketValuationId: targetMarketValuationId,
                } as MarketValuation;

                try {
                  logger.debug("CreateValuationWithoutError", JSON.stringify(marketValuationData, null, 2));
                  createMarketValuation(marketValuationData);
                  valuator.value = marketValuationData as MarketValuation;
                } catch (err) {
                  logger.error("Market Valuation Save Error", marketValuator.name, err);
                }
                return resolve(marketValuationData);
              })
              .catch((err) => {
                logger.error("Market Valuation Error", marketValuator.name, err);
                const mResp = {
                  errorMessage: "We were unable to value this vehicle.",
                  isComplete: true,
                  name: marketValuator.name,
                  vin: vin,
                  marketValuationId: targetMarketValuationId,
                  appraisalValue: null,
                  appraisalMessage: null,
                  vehicleDescription: null,
                  additionalInfo: null,
                };
                try {
                  logger.debug("CreateValuationWithError", JSON.stringify(mResp, null, 2));
                  createMarketValuation(mResp);
                } catch (err) {
                  logger.error("Market Valuation Fail Save Error", marketValuator.name, err);
                }

                // attrs.model.value = mResp as MarketValuation;
                valuator.value = mResp as MarketValuation;
                return resolve(mResp);
              });
          });
        }
      })
    );

    return {
      carvana: results[0] as MarketValuation,
      carmax: results[1] as MarketValuation,
      cargurus: results[2] as MarketValuation,
      webuyanycar: results[3] as MarketValuation,
      kbbInstantCash: results[4] as MarketValuation,
    };
  }

  return {
    fetchMarketValuations,
    carvana,
    carmax,
    cargurus,
    webuyanycar,
    kbbInstantCash,
    lutherBuysCars,
    createMarketValuation,
    loadMarketValuations,
    loadingComplete,
  };
};

export default useMarketValuation;
